import Link from '@mui/material/Link'
import NextLink from '@components/ui/Link'
import cn from '@utils/helpers/cn'
import { storyblokEditable } from '@storyblok/react'
import StoryblokComponent from '@components/Storyblok/StoryblokComponent'

const MuiLink = ({ blok }) => {
  return (
    <NextLink
      passHref={true}
      locale={blok.internal}
      {...(blok.href && { href: blok.href })}
      {...(blok.newWindow && { target: '_blank' })}
      {...((!blok.internal || blok.newWindow) && { rel: 'noopener' })}
    >
      <Link
        color={blok.color}
        variant={blok.variant}
        underline={blok.underline || 'none'}
        className={cn(blok.class)}
        component="div"
        {...storyblokEditable(blok)}
      >
        {blok.blocks
          ?.filter(
            (b) =>
              (b.hasOwnProperty('showBlock') && b.showBlock) ||
              !b.hasOwnProperty('showBlock'),
          )
          .map((nestedBlok, index) => (
            <StoryblokComponent
              index={index}
              blok={nestedBlok}
              key={nestedBlok._uid}
            />
          ))}
      </Link>
    </NextLink>
  )
}

export default MuiLink
